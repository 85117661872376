<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobTitle.jobTitleKh")
                }}</label>
                <input v-model.trim="model.job_title_kh" type="text" class="form-control"
                    :class="{ 'is-invalid': errors.has('job_title_kh') }" ref="job_title_kh" />
                <div class="invalid-feedback" v-if="errors.has('job_title_kh')">
                    {{ errors.first("job_title_kh") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobTitle.jobTitleEn")
                }}</label>
                <input v-model.trim="model.job_title_en" type="text" class="form-control"
                    :class="{ 'is-invalid': errors.has('job_title_en') }" />
                <div class="invalid-feedback" v-if="errors.has('job_title_en')">
                    {{ errors.first("job_title_en") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{ $t("jobTitle.role") }}</label>
                <Select v-model="model.role_id">
                    <Option v-for="(role, index) in roles" :key="index" :value="role.id">
                        {{ role.name }}
                    </Option>
                </Select>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
                    {{ $t("cancel") }}</ts-button>
                <ts-button v-if="!isUpdate" color="primary" outline :waiting="waiting_new"
                    :disabled="waiting_new || waiting" @click.prevent="onSaveAddNew">
                    {{ $t("saveAddNew") }}</ts-button>
                <ts-button color="primary" v-if="!isUpdate" :waiting="waiting" :disabled="waiting_new || waiting"
                    @click.prevent="onSave">
                    {{ $t("save") }}</ts-button>
                <ts-button v-if="isUpdate" color="primary" @click.prevent="onUpdate" :waiting="waiting">
                    {{ $t("update") }}</ts-button>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "job-titleForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                job_title_kh: null,
                job_title_en: null,
                role_id: null
            }
        };
    },
    computed: {
        ...mapState("humanResource/jobTitle", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    created() {
        this.getRole();
    },
    methods: {
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/jobTitle/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/jobTitle/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/jobTitle/update", {
                    id: this.edit_data.job_title_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function () {
                this.$refs.job_title_kh.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.job_title_kh = this.edit_data.job_title_kh;
                this.model.job_title_en = this.edit_data.job_title_en;
                this.model.role_id = this.edit_data.role_id;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.job_title_kh = null;
            this.model.job_title_en = null;
            this.model.role_id = null;
        },
        getRole() {
            this.$store
                .dispatch("humanResource/jobTitle/getRole", this.model)
                .then(response => {
                    this.roles = response.data
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "JOB TITLE",
                desc: not.text
            });
        }
    }
};
</script>
